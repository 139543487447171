<template>
    <div class="pa-4">

        <slot name="breadcrumb"></slot>
        <div class="d-sm-flex">
            <span v-if="title && !$vuetify.breakpoint.xsOnly" class="display-1 left mr-6 mb-4 text-no-wrap">{{title}}</span>
            <portal v-else to="title_portal">
                {{title !== undefined ? title : 'JF Assessoria Rural'}}
            </portal>
            <div style="width: 100%">
                <slot name="menu" ></slot>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Page',
        props: {
            title: null,
        },
    };
</script>

<style scoped>

</style>
