<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Criar Remessa</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col cols="4">
                            <v-menu v-model="dataVencimentoMenu"
                                       :close-on-content-click="false"
                                       transition="scale-transition"
                                       offset-y
                                       max-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dataVencimentoFormated"
                                    label="Data vencimento"
                                    readonly hide-details
                                    :disabled="titulos.length === 0"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dataVencimento"
                                           no-title :min="$moment(new Date()).format('YYYY-MM-DD')"
                                           locale="pt-br" required
                                           @input="dataVencimentoMenu = false">
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="titulos.length"
                                label="Quantidade de Títulos"
                                readonly hide-details disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="valorTotalTitulos"
                                label="Valor Total"
                                readonly hide-details disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-list class="py-0" v-if="titulos.length > 0">
                    <template v-for="titulo in titulos">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{titulo.nomePagador}}</v-list-item-title>
                                <v-list-item-subtitle>{{formatCpfCnpj(titulo.inscricaoPagador)}}</v-list-item-subtitle>
                                <v-list-item-subtitle></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <b>{{titulo.valor | formatNumber}}</b>
                                </v-list-item-action-text>
                                <v-list-item-action-text >
                                    AG: {{titulo.agencia}}-{{titulo.agenciaDigito}} C/C: {{titulo.conta}}-{{titulo.contaDigito}}
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider ></v-divider>
                    </template>
                </v-list>
                <div v-else class="text-center py-6">
                    <span class="subtitle-1 d-block">Nenhum título adicionado!</span>
                    <span class="subtitle-2 font-weight-light d-block">Clique abaixo para processar arquivo de títulos.</span>
                    <v-btn small color="primary" class="mt-4" @click="onAdicionarArquivoButtonClicked">Adicionar</v-btn>
                    <input v-show="false" ref="inputFileUpload" type="file"
                           @input="processarArquivosTitulos" >
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="criar">
                            Criar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "CriarRemessaDialog",
    computed: {
        valorTotalTitulos(){
            let valor = 0;
            if(this.titulos.length > 0){
                valor = this.titulos.reduce((acc, titulo) => {
                    return acc + titulo.valor;
                }, 0);

            }
            return this.$options.filters.formatNumber(valor);
        },
        dataVencimentoFormated: {
            get() {
                return this.$moment(this.dataVencimento).format('DD/MM/YYYY');
            },
            set(newValue) {
                if (newValue) {
                    this.dataVencimento = newValue;
                }
            },
        },
    },
    data() {
        return {
            dialog: false,
            loading: false,
            dataVencimento: null,
            dataVencimentoMenu: false,
            form1Valid: true,
            titulos: [
                /*{
                    nomePagador: "Danilo Oliveira Lima",
                    inscricaoPagador: "00918915155",
                    agencia: "234",
                    agenciaDigito: "8",
                    conta: "510032",
                    contaDigito: "1",
                    valor: 2.10,
                },
                {
                    nomePagador: "Danilo Oliveira Lima",
                    inscricaoPagador: "00918915155",
                    agencia: "234",
                    agenciaDigito: "8",
                    conta: "510032",
                    contaDigito: "1",
                    valor: 2.11,
                }*/
            ]
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.dataVencimento = this.$moment(new Date()).format('YYYY-MM-DD');
        },
        closeDialog() {
            this.dialog = false;
        },
        onAdicionarArquivoButtonClicked(){
            this.$refs.inputFileUpload.value = "";
            this.$refs.inputFileUpload.click();
        },
        processarArquivosTitulos(e){
            let file = e.target.files[0]
            let reader = new FileReader();
            let titulos = [];

            reader.onload = (e) => {
                let lines = e.target.result.split('\n');
                for(let i = 0; i < lines.length; i++){
                    //console.log(lines[i]);
                    if(lines[i].trim() === ""){
                        return;
                    }
                    let columns = lines[i].split(";")
                    //console.log(columns);
                    this.titulos.push({
                        nomePagador: columns[1].toUpperCase().substr(0, 40).normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                        inscricaoPagador: columns[2].replace(/\D/g,''),
                        agencia: columns[3].replace(/\D/g,''),
                        agenciaDigito: columns[4],
                        conta: columns[5].replace(/\D/g,''),
                        contaDigito: columns[6],
                        valor: parseFloat(columns[15].replace(",", ".")),
                    })
                }
            };
            reader.readAsText(file);
        },
        criar(){
            if(!this.$refs.form1.validate()){
                return false
            }
            this.loading = true;
            this.$apollo.mutate({
                mutation: gql`mutation ($dataVencimento: LocalDate!, $titulos:[TituloInput]!){
                        remessas:criarGrupoRemessas(dataVencimento: $dataVencimento, titulos: $titulos){
                            id
                            numero
                            descricao
                            valorTotal
                            titulos{
                                id
                                numero
                                nossoNumero
                                inscricaoPagador
                                nomePagador
                                agencia
                                agenciaDigito
                                conta
                                contaDigito
                                emissao
                                valor
                                vencimento
                            }
                            createdAt
                        }
                    }`,
                variables:{
                    dataVencimento: new Date(this.dataVencimento).getTime(),
                    titulos: this.titulos
                }
            }).then((result) => {
                this.$emit('saved', Object.assign({}, result.data.remessas));
                this.closeDialog();
            }).finally(()=>{
                this.loading = false;
            });
        },
        formatCpfCnpj(cpfCnpj){
            let formatCpf = (cpf) => {
                return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            }
            let formatCnpj = (cnpj) => {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
            }
            if(cpfCnpj.length === 11){
                return formatCpf(cpfCnpj)
            }else{
                return formatCnpj(cpfCnpj)
            }
        },
    }
}
</script>

<style scoped>

</style>